import { SectionLayoutType } from '@/shared/models/Section';
import { isEmpty } from '@/shared/utils/lodashFunc';
import { useTranslations } from 'contexts/translations';
import { useEffect } from 'react';
import { ISectionComponentProps } from '../utils';
import DefaultStandingsLayout from './Layout/DefaultStandingsLayout';
import StandingsBaseLayout from './Layout/StandingsBaseLayout';
import TotalStandings from './Layout/TotalStandings';

export default function StandingsContainer(props: ISectionComponentProps) {
  const { data, section } = props;
  const { placeholders } = data;
  const { extendTranslations } = useTranslations();

  useEffect(() => {
    if (!isEmpty(placeholders)) extendTranslations(placeholders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeholders]);

  if (section.layout === SectionLayoutType.DEFAULT) {
    return <DefaultStandingsLayout {...props} />;
  }

  if (section.layout === SectionLayoutType.LEAGUE) {
    return <TotalStandings {...props} />;
  }

  return <StandingsBaseLayout {...props} />;
}

import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStandingsStyles = makeStyles(theme => ({
    adRoot: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',

        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
}))
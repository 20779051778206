import { sectionMessages } from 'components/Sections/messages';
import { buildUrlFromParams, ISectionComponentProps } from 'components/Sections/utils';
import { useIntl } from 'react-intl';
import StatsTable from '@/shared/components/StatsTable';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Img } from '@/shared/components/Img/Img';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import ValidatedSelectInput from '@/shared/components/Form/Material/ValidatedSelectInput'
import Loader from '@/shared/components/Loader/Loader';
import { getLeagueSeasonsWithStandings } from 'services/seasons';
import { IStatisticsInfo } from '@/shared/models/Game';
import { getTranslation } from 'services/translations';
import { getSimpleStasInfoArrayPlaceholders } from '@/shared/utils/getPlaceholders';
import { useTranslations } from 'contexts/translations';
import { useRouter } from 'next/router';
import ResultsNavigation from 'components/Sections/ResultsNavigation'
import { Headline2, Paragraph2 } from '@/shared/components/Typography';
import { IGroup } from '@/shared/models/Group';
import { DEFAULT_STANDINGS_DATA } from '../const';
import { getLeagueStandings } from 'services/stats';
import { getLeague } from 'services/games';
import { useClub } from 'contexts/club';
import { AdManager } from '@/shared/components/AdManager/AdManager';
import Card from '@/shared/components/Card';
import { useStandingsStyles } from '../styles';

export default function DefaultStandingsLayout(props: ISectionComponentProps) {
    const router = useRouter()
    const { slug } = router.query
    const { club, prefix, pathPrefix, modes} = useClub()
    const { data, section } = props
    const { league, season, standingsStats, seasons, leagues, group: selectedGroup, placeholders, categories } = data
    const intl = useIntl()
    const statsLoaded = useRef(false)
    const seasonsLoaded = useRef(false)
    const translationsLoaded = useRef(false)
    const [currentLeagueDetails, setCurrentLeagueDetails] = useState(league)
    const [selectedSeason, setSelectedSeason] = useState(season?.id || 0)
    const [currentLeague, setCurrentLeague] = useState(league?.id || 0)
    const [currentStats, setCurrentStats] = useState(standingsStats)
    const [isStandingsLoading, setIsStandingsLoading] = useState(false)
    const [seasonsList, setSeasonsList] = useState(seasons)
    const [isSeasonsLoading, setIsSeasonsLoading] = useState(false)
    const [isTranslationsLoading, setIsTranslationsLoading] = useState(false)
    const [headerStrings, setHeaderStrings] = useState<string[]>([])
    const [headersChanged, setHeadersChanged] = useState(false)

    const { extendTranslations } = useTranslations()
    const classes = useStandingsStyles()

    // monitor to see if translations changed
    useEffect(() => {
        const headers: string[] = []

        currentStats.forEach((stats) => {
            const currentStatsheaders = Object.values(stats.header).map((e: Record<string, unknown>) => typeof e.acronym === 'string' ? e.acronym.toLowerCase() : '')
            currentStatsheaders.sort((a, b) => a.localeCompare(b))
            const headerString = currentStatsheaders.join(',')
            if (!headers.some(i => i === headerString)) headers.push(headerString)
        })

        let isNewHeader = false

        headers.forEach(header => {
            if (!headerStrings.some(i => i === header)) isNewHeader = true
        })

        if (isNewHeader) setHeaderStrings(headers)
        if (isNewHeader && headerStrings?.length) setHeadersChanged(true)
    }, [currentStats])

    // fetch stats if season or current page or current league changed
    useEffect(() => {
        if(statsLoaded.current && !isSeasonsLoading && selectedSeason) {
            fetchData({ season: selectedSeason, league: currentLeague });
        }
        const basePrefix = props.isPreview ? `${pathPrefix}/preview` : pathPrefix
        const basePath = `/${[basePrefix, slug[0]].join('/')}`
        const url = buildUrlFromParams(basePath, {
            groups: selectedGroup?.id,
            leagues: currentLeague,
            seasons: selectedSeason
        }, ['leagues', 'groups', 'seasons'], { })
        router.replace(url, undefined, { shallow: true })
        statsLoaded.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSeason, currentLeague])
    
    // fetch seasons if league changed
    useEffect(() => {
        if(seasonsLoaded.current) {
            fetchSeasons({ league: currentLeague })
        }
        seasonsLoaded.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLeague])

    // fetch new translations if headers changed
    useEffect(() => {
        if(translationsLoaded.current && headersChanged) {
            fetchNewTranslations(currentStats)
            setHeadersChanged(false)
        }
        translationsLoaded.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [headersChanged])

    function renderAdManager(blockId): JSX.Element {
        const adClub = club?.id ?? 0

        return (
            <div key={`${blockId}-ad`} className={classes.adRoot}>
                {adClub && (
                    <AdManager
                        adType='standings'
                        blockId={blockId}
                        sizes={[[300, 50], [234, 60]]}
                        color={modes.find(m => m.type === section?.mode.type)?.background}
                    />
                )}
            </div>
        )
    }

    return (
        <Box>
            <ResultsNavigation categories={categories} initialIndex={2} group={selectedGroup} league={currentLeagueDetails} seasonId={selectedSeason}/>
            <Box pb={4}>
                <Grid container spacing={4}>
                    {selectedGroup && (
                        <Grid item xs={12} sm={5} md={5}>
                            <ValidatedSelectInput
                                id='league'
                                value={currentLeague}
                                name='league'
                                label={intl.formatMessage(sectionMessages['section.label.league'])}
                                options={leagues?.map(e => ({ label: e.name, value: e.id }))}
                                onChange={(event) => {
                                    setCurrentLeague(Number(event.target.value))
                                }}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} sm={5} md={5}>
                        <ValidatedSelectInput
                            id='season'
                            value={selectedSeason}
                            name='season'
                            label={intl.formatMessage(sectionMessages['section.label.season'])}
                            options={seasonsList.map(e => ({ label: e.name, value: e.id }))}
                            onChange={(event) => setSelectedSeason(Number(event.target.value))}
                        />
                    </Grid>
                </Grid>
            </Box>
            {(selectedGroup && currentLeagueDetails )&& (
                <Box pb={4} display='flex' alignItems='center'>
                    <Img mods='w64' src={currentLeagueDetails?.picture ?? DEFAULT_TEAM_LOGO} failoverImage={DEFAULT_TEAM_LOGO}/>
                    <Box p={2}>
                        <Headline2>{currentLeagueDetails?.name}</Headline2>
                    </Box>
                </Box>
            )}
            <Box pb={4}>
                {(isStandingsLoading || isTranslationsLoading) ? <Loader/> : (
                    currentStats?.map(stats => {
                        const penaltyPoints = stats.footer || []
                        const hasPenaltyPoints = penaltyPoints.some(p => p.points !== 0 && Number.isInteger(p.points))
                        return (
                            <>
                                <StatsTable<IGroup>
                                    key={stats.phase?.id}
                                    entityKey='group'
                                    stats={stats}
                                    highlightRow={(group) => group?.id === selectedGroup?.id}
                                    getEntityNameNode={getEntityNameNode}
                                    entityNameHeaderNode={<span>{intl.formatMessage(sectionMessages['table.header.club'])}</span>}
                                    getFieldKey={(key, memberId, index) => getFieldKeys(memberId, index, league.id).getStatKey(key)}
                                />
                                {hasPenaltyPoints ? (
                                    <Box pb={4}>
                                        <Card>
                                            <Box p={4}>
                                                {penaltyPoints.map(p => {
                                                    if (!Number.isInteger(p.points) || p.points === 0) return null
                                                    return (
                                                        <Box key={p.group.id + '-penalty'} mb={2}>
                                                            <Paragraph2>
                                                                *{p.group?.full_name || ''}: {p.points} {(p.points === 1 || p.points === -1) ? intl.formatMessage(sectionMessages['standings.point']) : intl.formatMessage(sectionMessages['standings.points'])}
                                                            </Paragraph2>
                                                            {p.note ? <Paragraph2>
                                                                {intl.formatMessage(sectionMessages['standings.comment'])}: {p.note}
                                                            </Paragraph2> : null}
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        </Card>
                                    </Box>
                                ) : null}
                            </>
                        )
                    })
                )}
            </Box>
            <Box py={4}>
                {renderAdManager(club.id)}
            </Box>
        </Box>
    )

    async function fetchNewTranslations(stats: IStatisticsInfo<IGroup>[] | { rows: any[]; header: Record<string, unknown>; phase: { id: any; }; }[]) {
        const [userLanguage] = Object.keys(placeholders)
        setIsTranslationsLoading(true)

        let simpleStasInfo = {}

        stats?.forEach(stat => {
            simpleStasInfo = Object.assign(simpleStasInfo, getSimpleStasInfoArrayPlaceholders(stat))
        })

        const messages = await getTranslation(userLanguage, simpleStasInfo)
            .catch(() => {
                // TODO: show error
                return null
            })
        if(messages) {
            extendTranslations({
                [userLanguage]: messages
            })
        }
        setIsTranslationsLoading(false)
    }

    async function fetchData({ season, league }: { season: number, league: number}) {
        setIsStandingsLoading(true)
        const result = await getLeagueStandings({ clubPrefix: prefix as string, leagueId: league, seasonId: season, })
            .catch(() => [DEFAULT_STANDINGS_DATA.data])
            .finally(() => setIsStandingsLoading(false))
        setCurrentStats(result)
    }

    async function fetchSeasons({ league }: { league: number }) {
        setIsSeasonsLoading(true)
        const [results, newLeague] = await Promise.all([
            getLeagueSeasonsWithStandings({ clubPrefix: prefix as string, leagueId: league, groupId: selectedGroup?.id }).catch(() => ([])),
            getLeague(league, { clubPrefix: prefix as string })
        ])
        setSeasonsList(results)
        setCurrentLeagueDetails(newLeague)
        setIsSeasonsLoading(false)
        const selectedSeasonExist = results.find(e => e.id === selectedSeason)
        const currentSeasonExist = results.find(e => e.current)
        if(currentSeasonExist || selectedSeasonExist) {
            setSelectedSeason(currentSeasonExist ? currentSeasonExist?.id : selectedSeasonExist?.id)
            return
        }

        if (results.length) {
            setSelectedSeason(results[0]?.id)
            return
        }

        setSelectedSeason(0)
        setCurrentStats([{ header: {}, rows: [], footer: [] }])
    }

    function getFieldKeys(memberId: number, index: number, teamId: number) {
        return {
            getStatKey: (key: string) => `${memberId}-${teamId}-${index}-${key}`
        }
    }

    function getEntityNameNode(group): ReactNode {
        return (
            <Box display='flex' alignItems='center'>
                <Box mr={1}>
                    <Img mods='w24' 
                        src={group?.pic ?? DEFAULT_TEAM_LOGO}
                        failoverImage={DEFAULT_TEAM_LOGO}
                    />
                </Box>
                {group?.name}
            </Box>
        )
    }
}
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';

import { IGroup } from '@/shared/models/Group';
import { Img } from '@/shared/components/Img/Img';
import StatsTable from '@/shared/components/StatsTable';
import { Headline2 } from '@/shared/components/Typography';
import { DEFAULT_TEAM_LOGO } from '@/shared/components/Avatar/Avatar';
import ValidatedSelectInput from '@/shared/components/Form/Material/ValidatedSelectInput';
import { ISectionComponentProps } from 'components/Sections/utils';
import { sectionMessages } from 'components/Sections/messages';
import { IStatisticsInfo } from '@/shared/models/Game';
import { StatsType } from '../types';

const TotalStandings: React.FunctionComponent<ISectionComponentProps> = (props) => {
  const {
    data: { standingsStats: standingsStatsData, league },
    section,
  } = props;

  const standingsStats = useMemo(() => {
    const stats = standingsStatsData as StatsType[];
    return stats?.filter((s) => s.phase?.type === 'stage');
  }, [standingsStatsData]);

  const [currentStage, setCurrentStage] = useState<number>(standingsStats[0]?.phase?.id);

  const intl = useIntl();

  useEffect(() => {
    if (section.content.stage?.id && standingsStats.some((s) => s.phase?.id === section.content.stage.id)) {
      setCurrentStage(section.content.stage.id);
    }
  }, []);

  function getEntityNameNode(group): ReactNode {
    return (
      <Box display='flex' alignItems='center'>
        <Box mr={1}>
          <Img mods='w24' src={group?.pic ?? DEFAULT_TEAM_LOGO} failoverImage={DEFAULT_TEAM_LOGO} />
        </Box>
        {group?.name}
      </Box>
    );
  }
  function getFieldKeys(memberId: number, index: number, teamId: number) {
    return {
      getStatKey: (key: string) => `${memberId}-${teamId}-${index}-${key}`,
    };
  }

  function prepareStat() {
    const stats = standingsStats as StatsType[];
    return stats.find((s) => s.phase.id === currentStage) || { rows: [], header: {}, phase: {} };
  }

  return (
    <Box>
      <Box pb={4}>
        <Box display='flex' alignItems='center'>
          <Img mods='w64' src={league.picture ?? DEFAULT_TEAM_LOGO} failoverImage={DEFAULT_TEAM_LOGO} />
          <Box p={2}>
            <Headline2>{league.name}</Headline2>
          </Box>
        </Box>
      </Box>
      {standingsStats.length && section.content.stageDisplay === 'all' && (
        <Box pb={5}>
          <Grid item xs={12} sm={5} md={5}>
            <ValidatedSelectInput
              id='stage'
              value={currentStage}
              name='stage'
              label={intl.formatMessage(sectionMessages['section.label.stage'])}
              options={standingsStats?.map((e) => ({ label: e.phase.name, value: e.phase.id }))}
              onChange={(event) => {
                setCurrentStage(Number(event.target.value));
              }}
            />
          </Grid>
        </Box>
      )}
      <StatsTable<IGroup>
        hideStageName
        entityKey='group'
        stats={prepareStat() as IStatisticsInfo<IGroup>}
        getEntityNameNode={getEntityNameNode}
        entityNameHeaderNode={<span>{intl.formatMessage(sectionMessages['table.header.club'])}</span>}
        getFieldKey={(key, memberId, index) => getFieldKeys(memberId, index, league.id).getStatKey(key)}
      />
    </Box>
  );
};

export default TotalStandings;
